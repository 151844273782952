<template>
  <SayHello id="first" v-if="dataVorhanden > 0"
            txt="Hallo, deine Suchanfragen wurden vom Algorithmus analysiert.
             Das Ergebnis zeigt, nach welchen Kategorien du Online am häufigsten suchst.
              Es sind nicht unbedingt dieselben Themen, die dich in deinem Alltag beschäftigen.
Was fällt dir auf, wenn du das Ergebnis mit der Selbsteinschätzung von gerade eben vergleichst?"
            @finished="timer"></SayHello>
  <SayHello id="first" v-if="dataVorhanden < 1"
            txt="Hallo, deine Suchanfragen wurden vom Algorithmus analysiert.
             Das Ergebnis zeigt, nach welchen Kategorien du Online am häufigsten suchst.
              Es sind nicht unbedingt dieselben Themen, die dich in deinem Alltag beschäftigen."
            @finished="timer"></SayHello>
  <div class="row justify-content-center align-content-center content firstReflect" ref="diagramme">
    <div class="col col-6" v-if="dataVorhanden > 0">
      <p v-for="(items, index) in selfCheck"
         :key="items" class="reading wordshead">{{ index + 1 }}. {{ items }}</p>
    </div>
    <!--     Nur für Richtigen-Datensatz      -->
    <div v-if="dataVorhanden > 0" class="col col-3">
      <p v-for="(items, index) in sortedCat"
         :key="items" class="reading balkenFrame">
        {{ index + 1 }}. {{ kategorien[items] }}</p>
    </div>
    <div v-if="dataVorhanden > 0" class="col col-3">
      <p class="reading balken" v-for="items in sortedVal"
         :ref="setItemRef" :key="items">{{ items }}%</p>
    </div>
    <!--     Nur für Muster-Datensatz      -->
    <div v-if="dataVorhanden < 1" class="col col-6">
      <p v-for="(items, index) in sortedCat"
         :key="items" class="reading balkenFrame">
        {{ index + 1 }}. {{ kategorien[items] }}</p>
    </div>
    <div v-if="dataVorhanden < 1" class="col col-6">
      <p class="reading balken" v-for="items in sortedVal"
         :ref="setItemRef" :key="items">{{ items }}%</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import SayHello from '@/components/SayHello';

export default {
  name: 'reflect',
  components: { SayHello },
  props: {
    kategorien: Array,
    selfCheck: Array,
    predicted: Array,
    dataVorhanden: Number,
  },
  data() {
    return {
      itemRefs: [],
      // '', '#', '#', '#', '#', '#',
      colors: ['#08c47c', '#07a2e0', '#5447f1', '#a1027e',
        '#f8143c', '#ff7a11', '#727272'],
      sortedCat: [],
      sortedVal: [],
      alive: true,
    };
  },
  methods: {
    setItemRef(el) {
      this.itemRefs.push(el);
    },
    timer() {
      if (this.alive) {
        setTimeout(() => {
          this.$refs.diagramme.classList.add('shine');
        }, 100);
        setTimeout(() => {
          this.$emit('finished', 'fertig');
        }, 5000);
      }
    },
  },
  created() {

    let map = new Map();
    for (let i = 0; i < this.kategorien.length; i++) {

      map.set(i, this.predicted[i]);
    }
    const mapSort = new Map([...map.entries()].sort((a, b) => a[1] - b[1]));
    this.sortedCat = Array.from(mapSort.keys())
      .reverse();
    this.sortedVal = Array.from(mapSort.values())
      .reverse();
    //console.log(mapSort);
    //console.log(this.sortedCat);

  },
  mounted() {
    for (let i = 0; i < this.sortedVal.length; i++) {
      this.itemRefs[i].style.width = this.sortedVal[i] + '%';
      this.itemRefs[i].style.backgroundColor = this.colors[i];
    }
  },
  beforeUnmount() {
    this.alive = false;
  }
};
</script>

<style scoped>
.balken {

}

.balkenFrame {
  text-align: right;
}

.content {
  margin: 5vh 0;
}

.col {
  margin: 5vh 0;
}

.firstReflect {
  margin: 0 5vh;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}
.shine {
  opacity: 1;
}
</style>

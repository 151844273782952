<template>
  <div class="noOverflow">
  <say-hello class="sayHello" :txt="headline" @finished="show"></say-hello>
    <div class="row justify-content-center align-content-center content">
      <div class="col col-8">
        <h5 ref="most1" class="most3">{{ big5[0] }}, {{ big5[1] }} und {{ big5[2] }} </h5>
      </div>
    </div>
  <div id="fensterFly">
    <div class="container-fluid justify-content-center align-items-center">
      <div class="row justify-content-center suchfeld sayHello1">
        <div class="col-10 col-md-8 col-lg-6 suchLeiste">
          <say-hello-fly :txt="firstSearch" :searchword="big5[0]" ref="myChild1"
                         class="" @finished="fire1"></say-hello-fly>
        </div>
        <div class="col col-12 suchanfragen1">
          <a class="textElement" v-for="items in suchanfragen1"
             :ref="setItemRef" :key="items"> {{ items }} </a>
        </div>
      </div>
      <div class="row justify-content-center suchfeld sayHello2">
        <div class="col-10 col-md-8 col-lg-6 suchLeiste">
          <say-hello-fly :txt="secondSearch" :searchword="big5[1]" @finished="fire2"
                         ref="myChild2" class=""></say-hello-fly>
        </div>
        <div class="col col-12 suchanfragen2">
          <a class="textElement" v-for="items in suchanfragen2"
             :ref="setItemRef2" :key="items"> {{ items }} </a>
        </div>
      </div>
      <div class="row justify-content-center suchfeld sayHello3">
        <div class="col-10 col-md-8 col-lg-6 suchLeiste">
          <say-hello-fly :txt="thirdSearch" :searchword="big5[2]" @finished="fire3"
                         ref="myChild3" class=""></say-hello-fly>
        </div>
        <div class="col col-12 suchanfragen3">
          <a class="textElement" v-for="items in suchanfragen3"
             :ref="setItemRef3" :key="items"> {{ items }} </a>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

/* eslint-disable */

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SayHelloFly from '@/components/SayHelloFly';
import SayHello from '@/components/SayHello';

export default {
  name: 'flyingSearches',
  components: {
    SayHello,
    SayHelloFly
  },
  props: {
    searches: Array,
    searches2: Array,
    searches3: Array,
    big5: Array,
  },
  data() {
    return {
      i: 0,
      i2: 0,
      i3: 0,
      state: 0,
      breite: window.innerWidth,
      hohe: window.innerHeight,
      itemRefs: [],
      itemRefs2: [],
      itemRefs3: [],
      suchanfragen1: [],
      suchanfragen2: [],
      suchanfragen3: [],
      firstSearch: '',
      secondSearch: '',
      thirdSearch: '',
      headline: '',
      timeline: 0,
      done: false,

      durationShort: 6,
      durationLong: 12,
    };
  },
  mounted() {

    //Format Searches in color an size
    for (let i = 0; i < this.suchanfragen1.length; i++) {
      if (i % 2 === 0) {
        this.itemRefs[i].style.color = 'rgb(255, 255, 255)';
        this.itemRefs[i].classList.add('textGros');

      } else {
        this.itemRefs[i].style.color = 'rgb(155, 155, 155)';
        this.itemRefs[i].classList.add('textklein');

      }
    }

    for (let i = 0; i < this.suchanfragen2.length; i++) {
      if (i % 2 === 0) {
        this.itemRefs2[i].style.color = 'rgb(255, 255, 255)';
        this.itemRefs2[i].style.fontSize = '28px';

      } else {
        this.itemRefs2[i].style.color = 'rgb(155, 155, 155)';
        this.itemRefs2[i].style.fontSize = '18px';
      }
    }
    for (let i = 0; i < this.suchanfragen3.length; i++) {
      if (i % 2 === 0) {
        this.itemRefs3[i].style.color = 'rgb(255, 255, 255)';
        this.itemRefs3[i].style.fontSize = '28px';
      } else {
        this.itemRefs3[i].style.color = 'rgb(155, 155, 155)';
        this.itemRefs3[i].style.fontSize = '18px';
      }
    }

    gsap.registerPlugin(ScrollTrigger);

    let timeline = gsap.timeline({
      // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: '#fensterFly',
        start: 'top top', // top center bottom  20px 80%
        end: '+=' + this.hohe, // * 1.1, // end after scrolling 500px beyond the start
        markers: false,
        scrub: 0,
        pin: '#fensterFly',
        //onLeave: () => this.$emit('finished', 'fertig'),
        //toggleActions: "restart pause reverse pause"
      }
    });
    timeline.addLabel('start')

      // Show 1 Example
      .call(this.$refs.myChild1.typeWriter)
      .to('.sayHello1', {
        display: 'flex',
        opacity: 1,
        duration: this.durationLong,
      })

      .to('.sayHello1', { // Just make a short brake
        opacity: 1,
        duration: this.durationShort,
      })
      .call(this.fire1)
      .call(this.$refs.myChild1.markWord)
      .to('.sayHello1', { // Just make a short brake
        opacity: 1,
        duration: this.durationLong
      })
      .call(this.$refs.myChild1.markWord)
      .to('.sayHello1', {
        display: 'none',
        duration: this.durationLong,
        opacity: 0,
      })
      .call(this.$refs.myChild1.markWord)
      // Show 2 Example

      .call(this.$refs.myChild2.typeWriter)
      .to('.sayHello2', {
        display: 'flex',
        opacity: 1,
        duration: this.durationLong
      })
      .to('.sayHello2', { // Just make a short brake
        opacity: 1,
        duration: this.durationShort,
      })
      .call(this.fire2)
      .call(this.$refs.myChild2.markWord)
      .to('.sayHello2', { // Just make a short brake
        opacity: 1,
        duration: this.durationShort,
      })
      .call(this.$refs.myChild2.markWord)
      .to('.sayHello2', {
        display: 'none',
        opacity: 0,
        duration: this.durationLong
      })
      .call(this.$refs.myChild2.markWord)


      // Show 3 Example
      .call(this.$refs.myChild3.typeWriter)
      .to('.sayHello3', {
        display: 'flex',
        opacity: 1,
        duration: this.durationLong
      })
      .to('.sayHello3', { // Just make a short brake
        opacity: 1,
        duration: 2
      })
      .call(this.fire3)
      .call(this.$refs.myChild3.markWord)
      .to('.sayHello3', { // Just make a short brake
        opacity: 1,
        duration: this.durationShort,
      })
      .call(this.$refs.myChild3.markWord)
      .call(this.fertig);

    this.timeline = timeline;
  },
  created() {
    this.headline = 'Ich habe herausgefunden für was du dich interessierst. Woher ich das weiß? Scrolle nach unten, um deine Suchanfragen zu entdecken. Die meistgesuchten Wörter in deinem Datensatz sind:';

    this.suchanfragen1 = this.shuffle(this.searches);
    this.suchanfragen2 = this.shuffle(this.searches2);
    this.suchanfragen3 = this.shuffle(this.searches3);

    // Erste beispielhafte Suchanfrage, die kürzer als 20 Zeichen ist, wird aus Array rausgenomen und als Mustersuchesuche verwendet
    for (let i = 0; i < this.suchanfragen1.length; i++) {
      if (this.suchanfragen1[i].length < 30 ) {
        this.firstSearch = this.suchanfragen1[i];
        this.suchanfragen1.splice(i,1);
        break;
      }
    }

    for (let i = 0; i < this.suchanfragen2.length; i++) {
      if (this.suchanfragen2[i].length < 30) {
        this.secondSearch = this.suchanfragen2[i];
        this.suchanfragen2.splice(i,1);
        break;
      }
    }
    for (let i = 0; i < this.suchanfragen3.length; i++) {
      if (this.suchanfragen3[i].length < 30) {
        this.thirdSearch = this.suchanfragen3[i];
        this.suchanfragen3.splice(i,1);
        break;
      }
    }

  },

  methods: {

    fertig() {
      if (this.done === false) {
        this.$emit('finished', 'fertig');
        this.done = true;
      }
    },
    resize(e) {
      this.breite = window.innerWidth;
      this.hohe = window.innerHeight;
    },
    setItemRef(el) {
      this.itemRefs.push(el);
    },
    setItemRef2(el) {
      this.itemRefs2.push(el);
    },
    setItemRef3(el) {
      this.itemRefs3.push(el);
    },

    fire1() {

      if (this.i < this.itemRefs.length) {
        this.itemRefs[this.i].classList.add('fired');
        this.i += 1;
        setTimeout(() => {
          this.fire1();
        }, 50);
      }
    },

    fire2() {

      if (this.i2 < this.itemRefs2.length) {
        this.itemRefs2[this.i2].classList.add('fired');
        this.i2 += 1;
        setTimeout(() => {
          this.fire2(); // dont forget to change !
        }, 50);
      }
    },

    fire3() {

      if (this.i3 < this.itemRefs3.length) {
        this.itemRefs3[this.i3].classList.add('fired');
        this.i3 += 1;
        setTimeout(() => {
          this.fire3(); // dont forget to change !
        }, 50);
      }
    },
    show() {
      this.$refs.most1.classList.add('fired');
    },

    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
  },
  beforeUnmount() {
    this.timeline.kill();

  }
};
</script>
<style scoped>
#fensterFly {
  height: 100vh;
  width: 100vw;
 /* border: 2px dotted red;*/
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.container {
 /* border: 2px dotted green;*/
}


.suchanfragen1, .suchanfragen2, .suchanfragen3 {
  margin: 0;
  padding: 0;
 /* border: 2px dotted #38a130;*/
}

.textElement {
  margin: 5vh;
  text-decoration: none;
  color: white;
  font-family: IBM-Light, sans-serif;
  opacity: 0;
  transition: opacity 2s;
}



.sayHello1, .sayHello2,  .sayHello3 {
  /* border: 2px dotted greenyellow;*/
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100vw; /* Need a specific value to work */
  display: none;
  opacity: 0;
  /*border: 2px dotted blue;*/
}
.sayHello {
  margin-top: 10vh;
}

.most3 {
  text-align: left;
  opacity: 0;
  transition: opacity 2s;
}

.suchLeiste {
  position: absolute;
  top: 25%;
  background: rgb(0, 0, 0);
  box-shadow: 0 0 25px 50px rgb(0, 0, 0);
  padding: 2vh;
  z-index: 5;
}
.fired {
  opacity: 1;
}
.textGros {
  font-size: 28px;
}
.textKlein {
  font-size: 18px;
}
@media screen and (min-width: 800px) {
  .textGros {
    font-size: 22px;
  }
  .textKlein {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .textGros {
    font-size: 30px;
  }
  .textKlein {
    font-size: 18px;
  }
}
@media screen and (min-width: 1600px) {
  .textGros {
    font-size: 44px;
  }
  .textKlein {
    font-size: 24px;
  }
}
@media screen and (min-width: 2000px) {
  .textGros {
    font-size: 52px;
  }
  .textKlein {
    font-size: 28px;
  }
}
</style>

<template>
  <div class="row justify-content-center align-content-center content">
    <div class="col col-8">
      <p class="reading" ref="message"></p>
    </div>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: 'SayHello',
  data() {
    return {
      i: 0,
      speed: 60,
      alive: true,
    };
  },
  mounted() {
    this.typeWriter();
  },
  props: {
    txt: String,
  },
  methods: {
    typeWriter() {
      if (this.i < this.txt.length && this.alive) {
        this.$refs.message.innerHTML += this.txt.charAt(this.i);
        this.i += 1;
        setTimeout(() => {
          this.typeWriter();
        }, this.speed);
      } else {
        this.$emit('finished', 'fertig');
      }
    },
  },
  beforeUnmount() {
    this.alive = false;
  }
};
</script>
<style scoped>
p {
  color: white;
  text-align: left;
}
.reading {
  /*  font-size: 36px; */
 /* border: 2px dotted blue;*/
}
.col {
  /*border: 2px dotted green;*/
}
</style>

<template>
  <div class="container-fluid" id="ReadingFenster">
    <!-- Selbsteinschätzung -->
    <self-check class="fadeIn bord" v-if="state === 0" :kategories="kategories" @finished="selfCheck"></self-check>
    <!-- Reflexion -->
    <reflect class="fadeIn bord" v-if="state > 0" :kategorien="kategories" :selfCheck="selfPrio" :predicted="reflect" :dataVorhanden="dataVorhanden"
             @finished="stateManagement"></reflect>

    <!-- Allgemeine fakten -->
    <SayHello id="first bord" v-if="state > 1" :txt="textFakten" @finished="stateManagement"></SayHello>
    <diagramm class="fadeIn bord" id="diagramm" v-if="state > 2" @finished="stateManagement"
              :werte="faktenDiagramm"
              :beschriftung="faktenDiagramm2"></diagramm>
    <flying-searches2 class="fadeIn bord" v-if="state > 3" :searches="faktenSuche" :searches2="faktenSuche2" :searches3="faktenSuche3"
                      :big5="faktenMost5"
                      :headline="'Deine meistgesuchten Wörter sind:'"
                      @finished="stateManagement"></flying-searches2>

    <!-- Tagesablauf -->
    <SayHello class="bord margin" v-if="state > 4" :txt="textTagesablauf" @finished="stateManagement"></SayHello>
    <diagrammTag class="fadeIn bord" id="diagramm" v-if="state > 5" @finished="stateManagement"
                 :headline="'Hier ist die Häufigkeit deiner Suchanfragen über den tag verteilt.'"
                 :werte="tagesablaufDiagramm"></diagrammTag>

    <!-- Spitze des Eisbergs (Magic Moment) -->
    <magic-moment class="fadeIn bord" v-if="state > 6" :InteressenMost5="InteressenMost5" :NahrungMost5="NahrungMost5"
                  :UnterhaltungMost5="UnterhaltungMost5" :ReisenMost5="ReisenMost5" :GesundheitMost5="GesundheitMost5"
                  :WissenMost5="WissenMost5" :ShoppingMost5="ShoppingMost5" @finished="stateManagement"></magic-moment>

    <!-- Abschließender Text und Headline-Aanimation -->
    <SayHello class="bord margin" v-if="state > 7" :txt="textReflexion" @finished="stateManagement"></SayHello>
    <Headlines class="fadeIn bord" v-if="state > 8" :data="searches"></Headlines>
  </div>
</template>
<script>

/* eslint-disable */

import SayHello from '@/components/SayHello';
import FlyingSearches2 from '@/components/flyingSearches2';
import Diagramm from '@/components/diagramm';
import diagrammTag from '@/components/diagrammTag';
import SelfCheck from '@/components/selfCheck';
import reflect from '@/components/reflect';
import Headlines from '@/components/Headlines';
import MagicMoment from '@/components/magicMoment';

export default {
  name: 'Reading',
  components: {
    MagicMoment,
    SelfCheck,
    Diagramm,
    FlyingSearches2,
    diagrammTag,
    SayHello,
    reflect,
    Headlines
  },
  props: {
    dataVorhanden: Number,
    reflect: Array, // Daten von Kategorieverteilung

    fakten: Array, // Text zu Fakten
    faktenDiagramm: Array, // Diagrammwerte1
    faktenDiagramm2: Array, // Diagrammwerte2
    faktenMost5: Array, // Meistgesuchte Wörter allgemein
    faktenSuche: Array, // Suchanfragen Teil 1
    faktenSuche2: Array, // Suchanfragen Teil 2
    faktenSuche3: Array, // Suchanfragen Teil 3

    tagesablauf: Array, // Text zu Tagesablauf
    tagesablaufDiagramm: Array, //Digrammwerte zu Tagesablauf

    InteressenMost5: Array,
    NahrungMost5: Array,
    UnterhaltungMost5: Array,
    ReisenMost5: Array,
    WissenMost5: Array,
    ShoppingMost5: Array,
    GesundheitMost5: Array,

    searches: Array,
  },
  data()
  {
    return {
      state: 0,

      kategories: ['Wissen', 'Karriere', 'Shopping', 'Soziales', 'Lebensstil', 'Reisen', 'Gesundheit'],
      textFakten: '',
      textTagesablauf: '',
      textBeruf: '',
      textLebensstiel: '',
      textReflexion: '',

      selfPrio: null,
    };
  },
  created()
  {
    //console.log('Daten vorhanden: ' + this.dataVorhanden);
    if (this.dataVorhanden == 0)
    {
      this.state = 1;
    }
    this.textFakten = 'Ich habe von dir insgesamt ' + this.fakten[0] + ' Suchanfragen gefunden. Im Zeitraum von ' + this.fakten[1] + ' bis zum ' + this.fakten[2] + '. Durchschnittlich googelst du ' + this.fakten[3] + ' mal am Tag. Das sind ca. ' + this.fakten[4] + ' Suchanfragen im Monat. Hier kannst du den kompletten Zeitraum sehen.';
    this.textTagesablauf = 'Die meisten deiner Anfragen finden um ' + this.tagesablauf[0] + ' Uhr statt. Du schläfst im Schnitt ' + this.tagesablauf[1] + ' Stunden pro Nacht. Genauer gesagt zwischen ' + this.tagesablauf[2] + ' und ' + this.tagesablauf[3] + ' Uhr. Deine meistgesuchte Kategorie tagsüber ist ' + this.kategories[this.tagesablauf[4]] + ' und nachts ' + this.kategories[this.tagesablauf[5]] + '.';
    this.textReflexion = 'Herzlich Glückwunsch, dass du dich an dieser Stelle mit deinem digitalen Ich auseinandergesetzt hast. Jetzt bekommst du noch einmal die Gelegenheit, ganz in Ruhe deine Suchanfragen zu durchstöbern.';
  },
  updated()
  {
    if (this.state === 2 || this.state === 3 || this.state === 7 || this.state === 8)
    {
      window.scrollTo(0, document.body.scrollHeight);
    }
  },

  methods: {
    stateManagement()
    {
      this.state += 1;
    },
    selfCheck(val)
    {
      this.selfPrio = val;
      this.stateManagement();
    },
  },
  beforeUnmount()
  {
    alert('Es werden keine Daten von dir gespeichert, sobald du die Seite verlässt sind alle Daten weg.');
  }
};
</script>

<style scoped>
.content
{
  text-align: center;
}

.row
{
  width:  100%;
  margin: 5vh 0;
}

#first
{
  margin-top: 20vh;
}

.container-fluid
{
  padding: 0;
  margin:  0;
}

#ReadingFenster
{
  overflow: hidden;
}

.bord
{
  /*border: 2px dotted green;*/
}

.margin
{
  margin-top: 10vh;
}

#platzhalter
{
  height: 50vh;
  width:  100%;
}
</style>

<template>
  <div class="noOverflow">
    <div class="row justify-content-center align-items-center" id="suchFrame">
      <div class="suchdFeld col-6 col-md-4">
        <input class="colorfull" type="text" v-model="suchbegriff"
               id="input" placeholder="suche nach Begriffen">
        <i @click="suchen" class="bi bi-search lupe"></i>
      </div>
    </div>
    <div class="row" ref="canvasFrame" id="canvasFrame">
      <canvas ref="canvas" id="can"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headlines',
  /* eslint-disable */

  props: {
    data: Array,
  },
  data() {
    return {
      headline: [],
      suchbegriff: '',
    };
  },
  methods: {

    resize() {
      this.$refs.canvas.width = this.$refs.canvasFrame.clientWidth;//this.$refs.canvasFrame.innerWidth;
      this.$refs.canvas.height = this.$refs.canvasFrame.clientHeight; //this.$refs.canvasFrame.innerHeight;
    },

    getSearchesfor(array, schlagwort) {

      array = this.shuffle(array);
      let liste = [];

      let index = 0;
      let treffer = [];
      while (index < array.length) {//for (let j = 0; j < array.length; j++) {  // treffer.length < 5 ||

        if (array[index].toLowerCase()
          .includes(schlagwort.toLowerCase())) {

          treffer.push(array[index]);
        }
        index += 1;
      }
      for (let t in treffer) {
        liste.push(treffer[t]);
      }
      //console.log(treffer)
      return liste;
    },

    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },

    suchen() {
      // ruft draw auf
      if (this.getSearchesfor(this.data, this.suchbegriff).length > 0) {
        this.draw(this.getSearchesfor(this.data, this.suchbegriff));
        //console.log(this.getSearchesfor(this.data, this.suchbegriff));
      } else {
        this.draw(['']);
      }
    },
    draw(headlines) {

      //console.log(this.headlines[0]);
      let canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');
      let height = ctx.canvas.height;
      let width = ctx.canvas.clientWidth;
      let zufallswerte = [];
      let anzahl = 33;
      let fonts = ['14px IBM-Plex-Regular', '22px IBM-Light', '28px IBM-Regular', '14px IBM-Plex-Regular'];
      let farben = ['rgb(80,80,80)', 'rgb(182,182,182)', 'rgb(255,255,255)', 'rgb(80,80,80)'];
      let textEbene = 0;

      ctx.textAlign = 'center';

      if (anzahl > headlines.length) {
        anzahl = headlines.length;
      }
        for (let i = 0; i < anzahl; i++) {

          if (textEbene > 3) {
            textEbene = 0;
          } else {
            textEbene += 1;
          }

          let newObj = {
            velo: Math.random() * 2.2 + 1.4,
            size: Math.random() * 48 + 14,
            pos: width + Math.random() * width + width / 5,
            text: headlines[0], // + ' ' + index,
            textEbene: textEbene,
          };
          zufallswerte.push(newObj);
          headlines.splice(0, 1);
        }


      function loop() {
        ctx.fillStyle = 'rgb(0,0,0)';
        ctx.fillRect(0, 0, width * 1.1, height);

        for (let i = 0; i < anzahl; i++) {

          ctx.font = fonts[zufallswerte[i].textEbene];
          ctx.fillStyle = farben[zufallswerte[i].textEbene];

          ctx.fillText(zufallswerte[i].text, zufallswerte[i].pos, height / anzahl * i * 0.95 + 20);//height / anzahl );
          if (zufallswerte[i].pos > -width / 2) {
            zufallswerte[i].pos -= zufallswerte[i].velo;
          } else {
            if (headlines.length > 0) {
              zufallswerte[i].pos = width * 1.3;
              zufallswerte[i].text = headlines[0]; // + ' php' + index;
              headlines.splice(0, 1);
              //console.log(headlines);
            }
          }
        }
        requestAnimationFrame(loop);
      }

      loop();
      //   }
    }
  },

  updated() {
    this.suchen();
    this.resize();
  },
  mounted() {
    this.resize();
    this.suchen();
  },
  destroyed() {
    this.resize();
  }
};
</script>

<style scoped>
#can {
  margin: 0;
  /*border: 2px dotted red;*/
}

#input {
  background-color: #444444;
  border: none;
  width: 90%;
  font-family: IBM-Medium, sans-serif;
}

#input[text]:focus-visible, #input:focus-within, #input:checked, #input:enabled, #input:active {
  background-color: #444444;
  outline: none;
  text-decoration: none;
}

.col {
  margin: 5vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#suchFrame {
  height: 25vh;
}

#canvasFrame {
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 75vh;
  width: 100%;
  /*border: 2px dotted blue;*/
}

.suchdFeld {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444;
  padding: 5px;
  border-radius: 5px;
}

</style>

<template>
  <div>
    <div class="row align-content-center justify-content-center" id="contentBalken">
      <div v-for="(wert, index) in werte" :key="wert" class="tablee">
        <div class="tableOben">
          <div class="balken" :ref="setItemRef"></div>
        </div>
        <div class="tableUnten">
          <p class="beschriftung">{{ beschriftung[index] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'diagramm',
  props: {
    werte: Array,
    headline: String,
  },
  data() {
    return {
      itemRefs: [],
      beschriftung: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0],
    };
  },
  methods: {
    setItemRef(el) {
      this.itemRefs.push(el);
    }
  },
  mounted() {
    for (let i = 0; i < this.werte.length; i++) {
      this.itemRefs[i].style.height = this.werte[i] + '%';
    }
    setTimeout(() => {
      this.$emit('finished', 'fertig');
    }, 4000);
  }
};
</script>

<style scoped>
.tablee {
  width: 2vw;
  /*border: 1px dotted blue;*/
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}

.tableOben {
  height: 20vh;
  display: flex;
  align-items: flex-end;
}

#contentBalken {
  margin: 10vh 5vw;
  overflow-y: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0;
}

.beschriftung {
  margin: 5px;
  padding: 0;
  border-top: none;
  font-size: 12px;
}

.balken {
  height: 100%;
  background: linear-gradient(20deg, rgb(213, 59, 185), rgb(32, 144, 169));
  width: 8px;
  margin-bottom: 10px;
}

.row {
  /*border: 2px dotted red;*/
}
</style>

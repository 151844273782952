<template>
  <div class="searchField">
    <h5 id='text'><span class="colorfull" ref="message"></span></h5>
    <i @click="finish" class="bi bi-search lupe"></i>
  </div>
</template>

<script>

/* eslint-disable */
export default {
  name: 'SayHello',
  data() {
    return {
      i: 0,
      speed: 150,
      durchgelaufen: 0,
      finished: 0,
      alive: true,
    };
  },
  mounted() {
    //this.typeWriter();
  },
  props: {
    txt: String,
    searchword: String,
  },
  methods: {
    typeWriter() {

      if (this.i < this.txt.length  && this.alive) {
        this.$refs.message.innerHTML += this.txt.charAt(this.i);
        this.i += 1;
        setTimeout(() => {
          this.typeWriter();
        }, this.speed);
      } else {
        if (this.finished === 0) {
          this.durchgelaufen = 1;
        }
        this.finished = 1;
      }
    },

    markWord() {
      if (this.durchgelaufen === 1) {
        this.durchgelaufen = 2;
        let words = this.$refs.message.innerHTML.split(' ');
        this.$refs.message.innerHTML = '';
        this.$refs.message.classList.remove('colorfull');
        for (let i = 0; i < words.length; i++) {
          if (words[i].toLowerCase() === this.searchword.toLowerCase()) {
            this.$refs.message.innerHTML += '<span class="colorfull2">' + this.searchword + '</span>';
          } else {
            this.$refs.message.innerHTML += ' ' + words[i] + ' ';
          }
        }
      }
    },

    finish() {
      this.$emit('finished', 'fertig');
      this.markWord();
    },
  },
  beforeUnmount() {
    this.alive = false;
  }
};
</script>
<style scoped>
p {
  color: white;
}

.searchField {
  background-color: #212121;
  min-width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.lupe {
  font-size: 18px;
}

#text {
  width: 85%;
  padding: 1vh 0 0.5vh 0;
}

#searchword {
  color: white;
}
</style>

<template>
  <div class="container align-items-center justify-content-center">
    <div class="row">
      <div class="col-12">
        <p class="wordshead info">Frage {{state +1}} von 2</p>
      </div>
      <div class="col-12">
        <h4>
          Bevor du die Auswertung erhältst, benötige ich eine kleine Selbsteinschätzung von dir.
        </h4>
      </div>
      <div class="col">
        <p class="reading wordshead" v-if="state === 0">Wie oft googelst du am Tag?</p>
        <p class="reading wordshead"
           v-if="state === 1">Nach welcher Kategorie suchst du am Häufigsten?</p>
      </div>
      <form>
        <div v-if="state === 0" class="col col-12">
          <!--label for="eingabe">Suchanfragen pro Tag</label-->
          <input type="number"
                 class="eingabe"
                 v-model="howOften"
                 placeholder="z.b 5"/>
        </div>

        <div id="select" v-if="state === 1">
          <div class="col-12">
            <span>Prio 1: </span>
            <select class="eingabe" v-model="prio1">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 2: </span>
            <select class="eingabe" v-model="prio2">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 3: </span>
            <select class="eingabe" v-model="prio3">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 4: </span>
            <select class="eingabe" v-model="prio4">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 5: </span>
            <select class="eingabe" v-model="prio5">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 6: </span>
            <select class="eingabe" v-model="prio6">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

          <div class="col-12">
            <span>Prio 7: </span>
            <select class="eingabe" v-model="prio7">
              <option disabled value="">Bitte eines auswählen</option>
              <option v-for="kat in kategories" :key="kat" :value="kat">{{ kat }}</option>
            </select>
          </div>

        </div>

      </form>
      <div class="col col-12">
        <button @click="getData" class="btn">weiter</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selfCheck',
  methods: {
    getData() {
      if (this.state < 1) {
        this.state += 1;
      } else {
        this.$emit('finished', [this.prio1, this.prio2, this.prio3, this.prio4, this.prio5, this.prio6, this.prio7]);
      }
    },
  },
  data() {
    return {
      state: 0,
      howOften: null,
      prio1: null,
      prio2: null,
      prio3: null,
      prio4: null,
      prio5: null,
      prio6: null,
      prio7: null,
    };
  },
  props: {
    kategories: Array,
  },
};
</script>

<style scoped>
.col-12 {
  display: flex;
  justify-content: center;
  align-items: center;
 /* border: 2px dotted red;*/
}

.btn {
  color: white;
  border: 3px solid;
  border-image-slice: 1;
  border-radius: 20px;
  border-image-source: linear-gradient(90deg, rgb(213, 59, 185), rgb(32, 144, 169));
}

.col {
  margin: 3vh 0;
}

.btn:active, .btn:hover {
  color: #77dcee;
}

.eingabe {
  color: black;
  margin: 1vh 2vh;
}

.container {
 /* border: 2px dotted darkgreen;*/
  height: 100vh;
}
.info {
  color: #8c8c8c;
}

</style>

<template>
  <div>
    <div v-if="werte.length > 6" class="row align-content-center contentBalken" >
      <div v-for="(wert, index) in werte" :key="wert" class="tablee">
        <div class="tableOben">
          <div class="balken" :ref="setItemRef"></div>
        </div>
        <div class="tableUnten">
          <p class="beschriftung">{{ beschriftung[index] }}</p>
        </div>
      </div>
    </div>

    <div v-if="werte.length <= 6"
         class="row align-content-center justify-content-center contentBalken" >
      <div v-for="(wert, index) in werte" :key="wert" class="tablee">
        <div class="tableOben">
          <div class="balken" :ref="setItemRef"></div>
        </div>
        <div class="tableUnten">
          <p class="beschriftung">{{ beschriftung[index] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'diagramm',
  props: {
    werte: Array,
    beschriftung: Array,
    headline: String,
  },
  data() {
    return {
      itemRefs: [],
    };
  },
  methods: {
    setItemRef(el) {
      this.itemRefs.push(el);
    }
  },
  mounted() {
    for (let i = 0; i < this.werte.length; i++) {
      this.itemRefs[i].style.height = this.werte[i] + '%';
    }
    setTimeout(() => {
      this.$emit('finished', 'fertig');
    }, 5000);
  }
};
</script>

<style scoped>
.tablee {
  width: 10vw;
  /*border: 1px dotted blue;*/
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.tableOben{
  height: 20vh;
  display: flex;
  align-items: flex-end;
}

.contentBalken {
  margin: 10vh 10vw;
  overflow-y: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  padding: 0;
}

.beschriftung {
  margin: 5px;
  padding: 0;
  border-top: none;
  font-size: 12px;
}

.balken {
  height: 100%;
  background: linear-gradient(20deg, rgb(213, 59, 185), rgb(32, 144, 169));
  width: 20px;
}

.row {
  /*border: 2px dotted red;*/
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3d3d3d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(23, 104, 121);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(33, 150, 175);
}
</style>
